import React from "react";
import {
  DialogActions,
  DialogContentText,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { useSelector } from "react-redux";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { assignIcon, warning } from "../../assets";
import { EditorState } from "draft-js";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
const ConfirmSendMessageModal = ({ id, open, handleClose, data }) => {
  const { statusSendMessage } = useSelector((state) => state.messages);
  const [checked, setChecked] = React.useState(false);
  const history = useHistory();
  const [redirectCheckd, setRedirectCheckd] = React.useState(false);
  const handleChange = (event) => {
    data?.id === "unarchive-redirect"
      ? setRedirectCheckd(event.target.checked)
      : setChecked(event.target.checked);
  };
  const handleKeyPress = (e) => {
    const { keyCode } = e;
    if (keyCode === 13) {
      handleClose(id);
      setTimeout(() => {
        data.setEditorState(EditorState.createEmpty());
        data?.onEnterClick();
      }, 0);
    }
  };
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      className="info-modal"
      onKeyDown={handleKeyPress}
      tabIndex="0"
    >
      <LazyLoadImage
        src={data?.id === "unarchive-redirect" ? assignIcon : warning}
        className="assign-icon"
        alt="assign-icon"
      />
      <DialogTitle className="assign-operator-title">
        {data?.id === "unarchive-redirect" ? "Redirect ?" : t("Send Message")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="assign-operator-text">
          {data?.id === "unarchive-redirect"
            ? "You Can Redirect To The Same Conversation and reply"
            : t("Are You Sure You Want To Send This Message")}
        </DialogContentText>

        <div className="confirm-check-wrapper">
          <Checkbox
            checked={
              data?.id === "unarchive-redirect" ? redirectCheckd : checked
            }
            onChange={handleChange}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            style={{
              color: data?.id === "unarchive-redirect" ? "#fec423" : "#FCC101",
            }}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 25,
                borderRadius: 40,
              },
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
          <span>{t("Do not show this popup again")}</span>
        </div>
      </DialogContent>
      <DialogActions className="assign-operator-btns">
        <Button
          className="assign-operator-cancel-btn"
          onClick={() => handleClose(id)}
        >
          {t("Cancel")}
        </Button>
        <Button
          id="send-message-btn"
          disabled={statusSendMessage === "loading"}
          style={{
            color: data?.id === "unarchive-redirect" && "#fec423",
            border: data?.id === "unarchive-redirect" && "1px solid #fec423",
          }}
          type="submit"
          onClick={() => {
            handleClose(id);
            data?.id !== "unarchive-redirect"
              ? (() => {
                  setTimeout(() => {
                    data.setEditorState(EditorState.createEmpty());
                    data?.onEnterClick();
                  }, 0);
                  localStorage.setItem("showModal", checked);
                })()
              : (() => {
                  localStorage.setItem("redirectModal", redirectCheckd);
                  history.push(
                    `/messaging/${data?.websiteID}/${data?.conversationID}`
                  );
                })();
          }}
        >
          {statusSendMessage === "loading" ? (
            <CircularProgress className="circular-progress-delete-modal" />
          ) : data?.id === "unarchive-redirect" ? (
            "Redirect"
          ) : (
            t("Send")
          )}
        </Button>{" "}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmSendMessageModal;
